import {
  defaultItemsStoreOptions,
  defaultItemsStoreQuery,
  defaultStoreOptions
} from '@/utils/pinia/defaults'
import { defineStore } from 'pinia'
import { useApiClient } from '@/composables/api-client'
import { createQuery, createUrl } from '@/utils/pinia/helpers'

export function createItemsStore(key, options) {
  const storeOptions = Object.assign(
    {},
    defaultStoreOptions,
    defaultItemsStoreOptions,
    options
  )
  const defaultQuery = Object.assign({}, defaultItemsStoreQuery, options.query)

  return defineStore(key, {
    state: () => ({
      items: [],
      meta: null,
      url: '',
      query: null,
      isLastPage: true,
      ...storeOptions?.extends?.state?.()
    }),

    actions: {
      async fetchItems(requestQuery = {}, options = {}) {
        options = Object.assign({ resetBeforeFetch: true }, options)

        if (options.resetBeforeFetch) {
          this.reset()
        }

        const client = useApiClient()
        const paginationQuery =
          storeOptions.paginationType === 'page' ? { page: 1 } : {}
        const mergedQuery = Object.assign(
          {},
          defaultQuery,
          paginationQuery,
          requestQuery
        )
        const query = createQuery(storeOptions, mergedQuery)
        const url = createUrl(storeOptions.path, requestQuery, options)
        const requestOptions = Object.assign({}, { query })
        const result = await client.get(url, requestOptions, {
          cache: storeOptions.cache
        })

        if (storeOptions.pagination) {
          this.items = result.items
          this.meta = result._meta
          this.isLastPage =
            storeOptions.paginationType === 'lastId'
              ? result._meta.lastId === null
              : result._meta.pageCount < 2
        } else {
          this.items = result
          this.isLastPage = true
        }

        this.url = url
        this.query = mergedQuery

        return this.items
      },
      async fetchMoreItems() {
        if (this.isLastPage || !this.query) {
          return
        }

        const client = useApiClient()
        const lastId = this.items[this.items.length - 1].id
        const paginationQuery =
          storeOptions.paginationType === 'page'
            ? { page: this.query.page + 1 }
            : { last_id: lastId }
        const mergedQuery = Object.assign({}, this.query, paginationQuery)
        const query = createQuery(storeOptions, mergedQuery)
        const requestOptions = Object.assign({}, { query })
        const result = await client.get(this.url, requestOptions, {
          cache: storeOptions.cache
        })

        this.items = this.items.concat(result.items)
        this.query = mergedQuery
        this.isLastPage =
          result._meta.totalCount === this.items.length ||
          (storeOptions.paginationType === 'lastId'
            ? result._meta.lastId === null
            : result._meta.currentPage === result._meta.pageCount)

        if (storeOptions.pagination) {
          this.meta = result._meta
        }

        return this.items
      },
      reset() {
        this.items = []
        this.query = null
        this.meta = null
        this.isLastPage = true
      },
      updateItem(id, updates, idKey = 'id') {
        const targetId = id.toString()
        const index = this.items.findIndex(
          item => item[idKey].toString() === targetId
        )
        if (index > -1) {
          this.items[index] = Object.assign(this.items[index], updates)
        }
      },
      addLeft(data) {
        this.items.unshift(data)
      },
      addRight(data) {
        this.items.push(data)
      },
      removeItem(id, idKey = 'id') {
        const targetId = id.toString()
        const index = this.items.findIndex(
          item => item[idKey].toString() === targetId
        )
        if (index > -1) {
          this.items.splice(index, 1)
        }
        if (this.meta && this.meta.totalCount) {
          this.meta.totalCount = Math.max(this.meta.totalCount - 1, 0)
        }
      },
      ...storeOptions?.extends?.actions
    },

    getters: {
      ...storeOptions?.extends?.getters
    }
  })
}
